import ticketCode from '../../components/ticketCode.vue'
import lablePop from '../../components/lablePop.vue' //弹窗
export default {
    name: 'orderInfo',
    components: {
        ticketCode,
        lablePop
    },
    data() {
        return {
            orderInfo: null,
            tickeShow: false, //显示二维码
            id: null,
            pop_show: false
        }
    },
    created() {
        document.title = "订单详情";
        this.getOrderInfo();
    },
    methods: {
        // 支付
        payment(data) {
            let _this = this;
            if (typeof WeixinJSBridge == 'undefined') return;
            this.$UtilsApi.payment(data.id, {}).then(res => {
                let paymentData = JSON.parse(res.data.data.payment);
                WeixinJSBridge.invoke('getBrandWCPayRequest', {
                    "appId": _this.$appId, //公众号ID，由商户传入    
                    "timeStamp": paymentData.timestamp, //时间戳，自1970年以来的秒数    
                    "nonceStr": paymentData.nonceStr, //随机串    
                    "package": paymentData.package,
                    "signType": paymentData.signType, //微信签名方式：    
                    "paySign": paymentData.paySign //微信签名
                }, function(res) {
                    if (res.err_msg == "get_brand_wcpay_request:ok") {
                        var z_data = {
                            type: 'ok',
                            order_id: data.id,
                            price: data.price
                        };
                        data.group_status == 1 ? z_data.is_pt = 1 : '';
                        _this.$router.replace({
                            name: 'payState',
                            query: z_data
                        })

                    } else if (res.err_msg == "get_brand_wcpay_request:fail") {
                        var z_data = {
                            type: 'no',
                            order_id: data.id,
                            price: data.price
                        };
                        data.group_status == 1 ? z_data.to = 1 : '';
                        _this.$router.replace({
                            name: 'payState',
                            query: z_data
                        })

                    } else {
                        var z_data = {
                            type: 'qx',
                            order_id: data.id,
                            price: data.price
                        };
                        data.group_status == 1 ? z_data.to = 1 : '';
                        _this.$router.replace({
                            name: 'payState',
                            query: z_data
                        })
                    }
                });
            })

        },
        hidePop(e) {
            this.pop_show = false;
        },
        //出示二维码
        playSY(id) {
            this.id = id;
            this.tickeShow = true;
        },
        //关闭二维码弹窗
        IsCode(e) {
            this.tickeShow = false;
        },
        //判断订单是否过期
        getTimerState(order_time) {
            var timestamp = Date.parse(new Date()) / 1000;
            return timestamp - order_time
        },
        //判断是否为当日订单
        isToday(timestamp) {
            // 将时间戳转换为Date对象
            let date = new Date(timestamp * 1000); // 时间戳以秒为单位，需乘以1000转换为毫秒

            // 获取当前日期
            let today = new Date();

            // 判断日期是否为今日
            if (
                date.getDate() === today.getDate() &&
                date.getMonth() === today.getMonth() &&
                date.getFullYear() === today.getFullYear()
            ) {
                return true;
            } else {
                return false;
            }
        },
        //更新时间函数
        upTimer() {
            this.orderInfo.Countdown = this.$countdown(this.orderInfo.order_time);
            setTimeout(() => {
                this.upTimer();
            }, 1000);
        },
        //获取订单详情
        getOrderInfo() {
            this.$UtilsApi.orderInfo(this.$route.query.id, {}).then(res => {
                if (res.data.code == 0) {
                    res.data.data['Countdown'] = '';
                    this.orderInfo = res.data.data;
                    this.orderInfo.is_today = this.getTimerState(res.data.data.act_end_time) > 0 || (res.data.data.status == 1 && !this.isToday(res.data.data.order_time));
                    this.upTimer(); //调用倒计时函数
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        // 根据时间戳转换日期
        getNewDate(timer) {
            var timestr = new Date(timer)
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            return year + "." + (month < 10 ? '0' + month : month) + "." + (date < 10 ? '0' + date : date);
        },
        // 根据时间戳转换日期
        getDate(time) {
            var timestr = new Date(parseInt(time) * 1000);
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            var hour = timestr.getHours();
            var minute = timestr.getMinutes();
            var second = timestr.getSeconds();
            return year + "-" + (month < 10 ? '0' + month : month) + "-" + (date < 10 ? '0' + date : date) + " " + (hour < 10 ? '0' + hour : hour) + ":" + (minute < 10 ? '0' + minute : minute) + ":" + (second < 10 ? '0' + second : second);
        }
    },
}